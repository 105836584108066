import React, { useState } from "react"
import styled from "styled-components"
import { SubmitNext, SubmitBack } from "../elements/buttons.js"

//PAGE-SPECIFIC STYLES.
const MonthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`
const MonthNext = styled(SubmitNext)`
  width: 30px;
  height: 30px;
  padding: 0;
  color: black;
  &::after {
    left: -1px;
  }
  &:hover {
    &::after {
      transform: rotate(-45deg);
    }
  }
`
const MonthBack = styled(SubmitBack)`
  width: 30px;
  color: black;
  height: 30px;
  padding: 0;
  &::before {
    left: 1px;
  }
  &:hover {
    &::before {
      transform: rotate(135deg);
    }
  }
`
const Month = styled.h3`
  color: black;
  display: block;
  width: 55%;
  text-align: center;
`
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
const DateControl = ({ nextCb, backCb, month, year }) => {
  return (
    <MonthWrapper>
      <MonthBack
        onClick={() => {
          backCb()
        }}
      />
      <Month>
        {month} {year}
      </Month>
      <MonthNext
        onClick={() => {
          nextCb()
        }}
      />
    </MonthWrapper>
  )
}
const CourseList = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  z-index: 4;
  padding: 5px 0px;
`
const CourseCard = styled.div`
  width: 95%;
  height: 140px;
  border-radius: 10px;
  margin: 10px auto;
  border: 1px solid rgb(0, 0, 0, 0.1);
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  &::before {
    content: "";
    height: 140px;
    width: 3px;
    position: absolute;
    border-left: ${props => (!!props.active ? "6px solid purple" : "0px")};
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: border-left 0.2s ease-in-out;
  }
`
const Date = styled.h5`
  padding-left: 15px;
  margin: 15px 0 5px;
  letter-spacing: -0.04em;
`
const Name = styled.h3`
  padding-left: 15px;
  margin: 15px 0 5px;
  letter-spacing: -0.04em;
`
const DurStatusWrap = styled.div`
  width: 90%;
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  padding: 5px;
  margin: 0 auto;
`
const Duration = styled.span`
  letter-spacing: -0.04em;
`
const Status = styled(Duration)`
  color: green;
`
const Button = styled.button`
  background: hsla(0, 0%, 100%, 0.2);
  padding: 5px 16px;
  border-radius: 30px;
  height: 15px;
  float: right;
  margin: 0 10px;
  outline: none;
`
const CourseItem = ({
  startDate,
  endDate,
  courseInfo,
  activeCallback,
  active,
}) => {
  let status =
    courseInfo.status[0].toUpperCase() + courseInfo.status.substring(1)

  return (
    <CourseCard
      onClick={() => {
        activeCallback()
      }}
      active={active}
    >
      <Date>
        {startDate} - {endDate}
      </Date>
      <Name>
        {courseInfo.name.toUpperCase()} {courseInfo.package}
      </Name>
      <DurStatusWrap>
        <Status>{status}</Status>
        <Duration>
          Duration: {courseInfo.duration} {courseInfo.durationType}
        </Duration>
      </DurStatusWrap>
      <Button disabled={!!active}>Select</Button>
    </CourseCard>
  )
}
const CalColWrapper = styled.div`
  width: 35%;
  height: 100%;
  border-top-left-radius: 10px;
  padding: 10px 0;
  margin-bottom: 50px;
`

const CalendarColumn = ({ data, updateContentCb }) => {
  const [active, setActive] = useState(0)
  const [month, setMonth] = useState(() => {
    if (typeof window !== "undefined") {
      let todaysDate = new window.Date()
      let todaysMonth = todaysDate.getMonth()
      return months[todaysMonth]
    } else {
      return months[0]
    }
  })
  const [year, setYear] = useState(() => {
    if (typeof window !== "undefined") {
      let todaysDate = new window.Date()
      let todaysYear = todaysDate.getFullYear()
      return todaysYear
    }
  })

  const handleNext = () => {
    if (typeof window !== "undefined") {
      let index = months.indexOf(month)
      let newMonth = months[index + 1]
      let todaysDate = new window.Date(window.Date.now())
      let isWithinYearRange = todaysDate.getFullYear() + 1 >= year
      if (newMonth) {
        setMonth(newMonth)
      } else if (isWithinYearRange) {
        setYear(year + 1)
        setMonth(months[0])
      }
    }
  }
  const handleBack = () => {
    let index = months.indexOf(month)
    let todaysDate = new window.Date(window.Date.now())
    let isWithinYearRange = todaysDate.getFullYear() - 1 <= year
    let newMonth = months[index - 1]

    if (newMonth) {
      setMonth(newMonth)
    } else {
      if (isWithinYearRange) {
        let thisMonth = setMonth[todaysDate.getMonth() + 1]
        setYear(year - 1)
      }
    }
  }

  const appendDate = day => {
    let input = day
    if (typeof input === "string") {
      input = parseInt(input)
    }
    if (input > 3 && input < 21) return input + "th"
    switch (input % 10) {
      case 1:
        return input + "st"
      case 2:
        return input + "nd"
      case 3:
        return input + "rd"
      default:
        return input + "th"
    }
  }

  const formatStartDate = startDate => {
    let result = startDate
    if (typeof result === "object") {
      result = result.toString()
    }

    let date = result.split("T")[0].substring(5)
    let month = date.split("-")[0]
    result = appendDate(date.split("-")[1]) + " " + months[parseInt(month) - 1]

    return result
  }
  const formatEndDate = (startDate, duration, durationType) => {
    let result = startDate
    if (window) {
      let endDate = new window.Date(result)

      durationType === "days"
        ? (endDate = new window.Date(
            endDate.setDate(endDate.getDate() + duration)
          ))
        : (endDate = new window.Date(
            endDate.setDate(endDate.getDate() + duration)
          ))
      let month = endDate.getMonth()
      //The if blocks below might be useless.
      if (month < 0) {
        month += 12
      } else if (month > 12) {
        month -= 12
      }

      endDate = appendDate(endDate.getDate()) + " " + months[month]

      return endDate
    }
  }
  const renderData = data => {
    if (Array.isArray(data) && data.length > 0) {
      const result = data.map((course, index) => {
        const doMonthsMatch = () => {
          let parsedMonthFromDate = course.startDate.split("-")[1]
          let parsedMonthIndex = parseInt(parsedMonthFromDate) - 1
          let startDateMonth = months[parsedMonthIndex]

          // let startDateMonth =
          //   months[parseInt(course.startDate.split("-")[1]) - 1]

          return month === startDateMonth
        }

        const doYearsMatch = () => {
          if (typeof window !== "undefined") {
            let startDateYear = new window.Date(course.startDate).getFullYear()
            return year === startDateYear
          }
        }

        if (doMonthsMatch() && doYearsMatch()) {
          let startDate = formatStartDate(course.startDate)
          let endDate = formatEndDate(
            course.startDate,
            course.duration,
            course.durationType
          )

          return (
            <CourseItem
              key={"courseItem" + index}
              startDate={startDate}
              endDate={endDate}
              courseInfo={course}
              active={index === active}
              activeCallback={() => {
                setActive(index)
                updateContentCb(index)
              }}
            />
          )
        }
      })

      return result
    } else {
      return <p>Gah! Looks like there isn't any course available.</p>
    }
  }

  return (
    <CalColWrapper>
      <DateControl
        nextCb={handleNext}
        backCb={handleBack}
        month={month}
        year={year}
      />
      <CourseList>
        {typeof data !== undefined && data.length < 1
          ? renderData()
          : renderData(data)}
      </CourseList>
    </CalColWrapper>
  )
}

export default CalendarColumn
