import React from "react"
import styled from "styled-components"
import Redirect from "../../services/redirect.js"
import { Link } from "gatsby"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
const DateContentWrap = styled.div`
  width: 70%;
  height: auto;
  background-color: rgb(26, 28, 40);
  backdrop-filter: blur(50px);
  border-radius: 10px;
  padding: 0 20px;
  color: white;
`

//COURSE SCHEDULE TABLE
const SubjectCard = styled.tr`
  border-radius: 10px;
  background-color: white;
  width: 85%;
  min-height: 30px;
  padding: 0 5%;
  margin: 15px auto;
  letter-spacing: -0.04em;
  color: black;
  display: block;
`
const HRow = styled(SubjectCard).attrs({ as: "tr" })`
  color: white !important;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
`
const Subject = styled.th`
  color: inherit;
  width: 200px;
`
const H_Subject = styled(Subject).attrs({ as: "th" })``
const SubjectIcon = styled.h5`
  min-width: 50%;
  max-width: 70%;
  margin: 10px 0;
  border-radius: 10px;
  color: inherit;
  text-align: left;
  line-height: 1.556;
  font-size: 16px;
  font-weight: 500;
`
const HSubj = styled(SubjectIcon)`
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  font-weight: 400;
  font-size: 0.9em;
`
const Td = styled.td`
  font-size: 0.9em;
  width: 30%;
  color: inherit;
  text-align: center;
`
const SDate = styled(Td)``
const H_SDate = styled(SDate).attrs({ as: "th" })``
const EDate = styled(Td)``
const H_EDate = styled(EDate).attrs({ as: "th" })``

const Row = ({ rowData }) => {
  const appendDate = day => {
    let input = day
    if (typeof input === "string") {
      input = parseInt(input)
    }
    if (input > 3 && input < 21) return input + "th"
    switch (input % 10) {
      case 1:
        return input + "st"
      case 2:
        return input + "nd"
      case 3:
        return input + "rd"
      default:
        return input + "th"
    }
  }
  const formatStartDate = dateString => {
    if (typeof dateString === "object") {
      dateString = dateString.toString()
    }
    let date = dateString.split("T")[0].substring(5)
    let month = parseInt(date.split("-")[0])
    // parseInt(month) < 12 ? month : (month = 12 - month)
    if (month < 0) {
      month += 12
    } else if (month > 12) {
      month -= 12
    }
    let result = appendDate(date.split("-")[1]) + " " + months[month - 1]
    return result
  }
  const formatEndDate = endDate => {
    if (typeof endDate === "object") {
      endDate = endDate.toString()
    }
    let date = endDate.split("T")[0].substring(5)
    let month = parseInt(date.split("-")[0])
    // parseInt(month) < 12 ? month : (month = 12 - month)
    if (month < 0) {
      month += 12
    } else if (month > 12) {
      month -= 12
    }
    let result =
      appendDate(date.split("-")[1]) + " " + months[parseInt(month - 1)]
    return result
  }
  return (
    <SubjectCard>
      <Subject>
        <SubjectIcon>{rowData.subject || "CARS I"}</SubjectIcon>
      </Subject>
      <SDate>{formatStartDate(rowData.startDate)}</SDate>
      <EDate>{formatEndDate(rowData.endDate) || "20th July"}</EDate>
    </SubjectCard>
  )
}

// TABLE HEADINGS
const THead = ({ sub, sDate, eDate, Lec, Qui }) => (
  <HRow>
    <H_Subject>
      <HSubj>{sub || "CARS I"}</HSubj>
    </H_Subject>
    <H_SDate>{sDate || "10th July"}</H_SDate>
    <H_EDate>{eDate || "20th July"}</H_EDate>
  </HRow>
)

const ScheduleTable = ({ data }) => (
  <table style={{ width: "100%" }}>
    <thead>
      <THead sub={"Subject"} sDate={"Start Date"} eDate={"End Date"} />
    </thead>
    <tbody>
      {data &&
        data.map((row, index) => (
          <Row key={"schedule_table_row" + index} rowData={row} />
        ))}
    </tbody>
  </table>
)

//  TABLE HEADER.
const HeaderWrap = styled.div`
  max-height: 50px;
  width: 90%;
  border-radius: inherit;
  padding: 19px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`
const ItemName = styled.h3`
  color: white;
  display: block;
  width: auto;
  text-align: left;
  padding: 0;
  margin: 0;
`
const HStat = styled.div`
  margin: 0;
  padding: 5px 16px;
  color: green;
  background-color: hsla(0, 0%, 100%, 0.2);
  line-height: 1.5556;
  border-radius: 30px;
  height: 25px;
  text-align: center;
  font-weight: 400;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`
const BuyBtn = styled(HStat).attrs({ as: "button" })`
  color: white;
  background-color: #f50057;
  outline: none;
  border: none;
  margin-left: 10px;
`
const DemoBtn = styled(HStat).attrs({ as: "button" })`
  outline: none;
  border: none;
  color: white;
  margin-left: 10px;
`
const BtnWrapper = styled.div`
  width: auto;
  margin-left: auto;
  position: relative;
  left: 22px;
  top: 2px;
`

const Disclaimer = styled.div`
  width: 90%;
  margin: 1em auto;
  font-size: 14px;
`
const DateContent = ({ data }) => {
  const handleBuy = () => {
    //Account for SSR (no window in server-side.)
    if (typeof window !== "undefined") {
      let currentBucket = window.localStorage.getItem("__buy")
      let item = []
      //No prev. item in bucket
      if (currentBucket === null) {
        item.push(data)
        window.localStorage.setItem("__buy", JSON.stringify(item))
      } else if (
        //Prev.item is set and is an array (should be an array)
        currentBucket !== null &&
        Array.isArray(JSON.parse(currentBucket))
      ) {
        currentBucket = JSON.parse(currentBucket)
        let addDataToCurrBucket = true
        //Check for duplicates.
        currentBucket.map((bucketItems, index) => {
          let identical = []
          for (var items in bucketItems) {
            if (bucketItems[items] === data[items]) {
              identical.push(true)
            } else {
              if (items !== "schedule") {
                identical.push(false)
              }
            }
          }

          if (identical.indexOf(false) === -1) {
            addDataToCurrBucket = false
          }
        })

        if (addDataToCurrBucket) {
          currentBucket.push(data)
          window.localStorage.setItem("__buy", JSON.stringify(currentBucket))
        }
      }
      // window.localStorage.setItem("__buy", JSON.stringify(data))
      Redirect("/app/checkout")
    }
  }
  const isEnrollable = () => {
    let startDate = new Date(data.startDate)
    let todaysDate = new Date(Date.now())
    return startDate > todaysDate
  }
  if (data) {
    return (
      <DateContentWrap>
        <HeaderWrap>
          <ItemName>
            {data.name} Course: {data.startDate.split("-")[2].split("T")[0]}{" "}
            {months[parseInt(data.startDate.split("-")[1])]}
          </ItemName>
          <BtnWrapper>
            <Link to="/contact">
              <DemoBtn>Request Demo</DemoBtn>
            </Link>
            {isEnrollable() ? (
              <BuyBtn onClick={() => handleBuy()}>Enroll Now</BuyBtn>
            ) : null}
          </BtnWrapper>
        </HeaderWrap>
        <ScheduleTable data={data.schedule} />
        <Disclaimer>
          * Subject deadlines are semi-flexible, and somewhat depend on the
          student's needs.
        </Disclaimer>
      </DateContentWrap>
    )
  } else {
    return <></>
  }
}

export default DateContent
